.stage-cube-cont{
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 15%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.cubespinner{
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div{
        position: absolute;
        width: 200px;
        height: 200px;
        // border: 1px solid #ccc;
        // border-radius: 4px;
        background: rgba(255, 255, 255, 0.6);
        font-size: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 5px 0 whitesmoke;

        img{
            position: absolute;
            height: 120px;
            width: auto;
            top: 64px;
        }
    }

    .face1{
        transform: translateZ(100px);
    }
    .face2{
        transform: rotateY(90deg) translateZ(100px);
    }
    .face3{
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    }
    .face4{
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    }
    .face5{
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    }
    .face6{
        transform: rotateX(-90deg) translateZ(100px);
    }
}

@keyframes spincube{
    from,to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16%{
        transform: rotateY(-90deg);
    }
    33%{
        transform: rotateY(-90deg) rotateZ(90deg);
    }
    50%{
        transform: rotateY(-180deg) rotateZ(90deg);
    }
    66%{
        transform: rotateY(-270deg) rotateZ(90deg);
    }
    83%{
        transform: rotateX(90deg) rotateZ(90deg);
    }
}

@media screen and (max-width: 768px) {
    .stage-cube-cont{
        width: 100%;
        position: relative;
        height: 445px;
    }
    .cubespinner{
        transform-origin: 50% 50% 0; /* Center the transformation origin */
        margin: 0 auto; /* Center the cube horizontally */
        width: 100px; /* Set width to 100px for mobile */
        height: 100px;
        div{
            width: 100px;
            height: 100px;
            font-size: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            // box-shadow: 0 0 20px 0 whitesmoke;
    
            img{
                position: absolute;
                height: 68px;
                width: auto;
                top: 30px;
            }
        }
        .face1{
            transform: translateZ(50px);
        }
        .face2{
            transform: rotateY(90deg) translateZ(50px);
        }
        .face3{
            transform: rotateY(90deg) rotateX(90deg) translateZ(50px);
        }
        .face4{
            transform: rotateY(180deg) rotateZ(90deg) translateZ(50px);
        }
        .face5{
            transform: rotateY(-90deg) rotateZ(90deg) translateZ(50px);
        }
        .face6{
            transform: rotateX(-90deg) translateZ(50px);
        }
    }
}