.page{
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags{
    bottom: auto;
    top: 35px;
}

.tags{
    color: #ffd700;
    opacity: 0.8;
    position: absolute;
    bottom: 15px;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}

.bottom-tag-html{
    margin-left: -20px;
}

.container{
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,
.contact-page,
.project-page{
    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1{
            color: #ffd700;
            font-size: 53px;
            font-family: 'Coolvetica';
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;

            &::before{
                content: '<h1>';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.8;
                line-height: 18px;
            }

             &::after{
                content: '<h1/>';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                line-height: 18px;
                position: absolute;
                left: -30px;
                bottom: -20px;
                margin-left: 20px;
                opacity: 0.8;
            }
        }

        p{
            font-style: 13px;
            color: white;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;
            font-size: 15px;

            &:nth-last-of-type(1){
                animation-delay: 1.1s;
            }
            &:nth-last-of-type(2){
                animation-delay: 1.2s;
            }
            &:nth-last-of-type(3){
                animation-delay: 1.3s;
            }
        }
    }

    .text-animate-hover{
        &:hover{
            color: white;
        }
    }
}

@media screen and (max-width: 1250px) {    
    .tags{
        bottom: 15px;
        left: 85px;
        font-size: 18px;
        font-family: 'La Belle Aurore';
    }
    
    .bottom-tag-html{
        margin-left: -16px;
    }
}

@media screen and (max-width: 768px){
    .page{
        overflow-y: scroll;
        overflow-x: hidden;
    }
    
    .top-tags{
        top: 0px;
    }
    
    .tags{
        bottom: 100px;
        left: 15px;
        font-size: 12px;
        font-family: 'La Belle Aurore';
    }
    
    .bottom-tag-html{
        margin-left: -10px;
    }

    .container {
        // height: auto;
        // min-height: auto;
    
        &.contact-page,
        &.about-page,
        &.project-page {
          .text-zone {
            position: initial;
            transform: none;
            width: 100%;
            display: block;
            padding: 20px;
            box-sizing: border-box;
          }
        }
    }

    .text-zone{
        width: 100% !important;
        left: 10% !important;
        top: 35% !important;

        h1{
            font-size: 47px !important;
            line-height: 40px !important;

            &::before{
                font-size: 12px !important;
                position: absolute;
                margin-top: -10px;
                left: 0 !important;
                opacity: 1 !important;
                line-height: 1px !important;
            }
            &::after{
                font-size: 12px !important;
                position: absolute;
                left: -20px !important;
                line-height: 1px ;
            }
        }
        h2{
            width: 90%;
        }
    }
  }

@media screen and (max-width: 481px){
    .text-zone{
        position: absolute;
        left: 10% !important;
        max-height: 60% !important;

        h1{
            font-size: 27px !important;
            line-height: 40px !important;
        }
    }
}