.project-showcase {
    height: 100vh;
    display: grid;
    place-items: center;
    color: white;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.parallax-image {
    display: block;
    height: 65vh;
    width: 100vw;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

section {
    padding: 4rem;
    font-size: 20px;
    font-family: 'Coolvetica';
    background: #555555;
    
    h2 {
        font-size: 40px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }
}

h2:hover{
    color: #d6bb20;
}

a{
    color: #ffd700;
    text-decoration: none;
}

.scroll-alert{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    background: radial-gradient(circle at 0% 50%, #111 , #111, transparent);
    color: white;
    opacity: 0;
    animation: fadeOut 6s backwards;

    i{
        position: absolute;
        top: 15%;
        font-size: 36px;
    }

}

.back_btn{
    position: fixed;
    top: 10px;
    right: 20px;
    font-size: 22px;
    font-weight: 400;
    text-decoration: none;
    color: yellow;
    cursor: pointer;
    animation: glow 2s infinite;
}

@keyframes glow {
    0%{
        text-shadow: 0 0 10px white;
    }
    50%{
        text-shadow: 0 0 30px white;
    }
    100%{
        text-shadow: 0 0 10px white;
    }
}

footer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-family: 'coolvetica';
    font-size: 18px;
}

@media screen and (max-width: 1250px){
    .parallax-image {
        background-size: contain;
        background-repeat: no-repeat;
    }

    footer{
        height: 50px;
        font-size: 12px;
    }

    section{
        font-size: 16px;
    }
}
