.contact-form{
    width: 35%;
    height: 100%;
    top: 0;
    padding-top: 11%;
    margin-left: 0;
    position: absolute;
    right: 10%;
    overflow: hidden;

    ul{
        padding: 0;
        margin: 0;

        li{
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half{
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child{
                margin-left: 0;
            }
        }
    }

    input[type="text"],
    input[type="email"]{
        width: 100%;
        border: 0;
        background: #3a7696;
        height: 50px;
        font-size: 16px;
        color: white;
        padding: 0 20px;
        box-sizing: border-box;

        &::placeholder{
            color: white;
        }
    }

    textarea{
        width: 100%;
        border: 0;
        background: #3a7696;
        height: 50px;
        font-size: 16px;
        color: white;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;

        &::placeholder{
            color: rgb(255, 255, 255);
        }
    }

    .flat-button{
        color: #ffd700;
        background: 0 0;
        font-size: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #ffd700;
        float: right;
        border-radius: 4px;
    }
}

.info-button{
    display: none;
}

.info-map{
    position: absolute;
    background: #54d372;
    bottom: 50px;
    left: 10%;
    border-radius: 12px;
    z-index: 99999;
    width: 267px;
    padding: 20px;
    color: black;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 2s 3s ease-in;
    animation-fill-mode: forwards;

    span{
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: orangered;
    }
}

@media screen and (max-width: 768px) {
    .contact-form{
        padding: 20px;
        position: relative;
        margin-top: 60px ;
        left: 10%;
        width: 70%;
    }
    .text-zone{
        p{
            h3{
                opacity: 1 ;
            }
        }
    }
    .info-map{
        display: none;
    }

    .info-button{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 26%;
        left: 40%;
        height: 3rem;
        width: 8rem;
        background: transparent;
        border: 1px solid yellow;
        color: yellow;
        border-radius: 4px;
    }
}