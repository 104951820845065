.nav-bar{
    background: #333333;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo{
        display: block;
        padding: 8px 0;
        
        img{
            display: block;
            margin: 6px auto;
            width: 24px;
            height: auto;
    
            &.sub-logo{
                width: 50px;
            }
        }
    }
    nav{
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 20%;
        margin-top: 120px;
        width: 100%;
    
        a{
            font-size: 22px;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i{
                transition: all 0.3s ease-out;
            }

            &:hover{
                color: #ffd700;

                svg{
                    opacity: 0;
                }

                &::after{
                    opacity: 1;
                }
            }
            &::after{
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child{
                &::after{
                    content: 'HOME';
                }
            }
        }

        a.about-link{
            &::after{
                content: 'ABOUT';
            }
        }

        a.contact-link{
            &::after{
                content: 'CONTACT';
            }
        }

        a.project-link{
            &::after{
                content: 'PROJECTS';
            }
        }

        a.active{
            svg{
                color: #ffd700;
            }
        }
    }
    ul{
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li{
            a{
                padding: 7px 0;
                display: block;
                font-size: 18px;
                line-height: 20px;

                &:hover svg{
                    opacity: 0.6;
                }
            }
        }
    }
}

.hamburger-icon, .close-icon {
    display: none;
}

@media screen and (max-width: 800px) {
    .nav-bar { 
      background: transparent;
      position: initial;
      height: auto;
      min-height: auto;

      ul{
        display: flex;
        justify-content: center;
        bottom: 10px;
        gap: 20px;
        z-index: 9999;

        a:hover svg{
            opacity: 1 !important;
        }
      }
  
      nav{
        display: none;
        width: 100%;
        top: 0;
        left: 0;
        background: #333333;
        color: white;
        height: 70px;
        margin: 0;
        z-index: 2;
        position: fixed;
  
        &.mobile-show {
          display: flex;
          justify-content: space-around;
        }
  
        a {
          display: flex;
          color: antiquewhite;
          justify-content: center;
          align-items: center;
        }
  
        a::after {
          opacity: 1;
          position: initial;
          width: auto;
          margin-left: 10px;
        }
  
        a svg {
          opacity: 1 !important;
        }
       }
  
       .hamburger-icon{
          position: absolute;
          top: 10px;
          right: 10px;
          display: block;
       }
       .close-icon{
         position: absolute;
         top: 35px;
         right: 10px;
         display: block;
       }
    }
}

