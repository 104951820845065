.card-container{
    width: 90%;
    height: 90%;
    // box-shadow: 0 0 10px white;
    display: flex;
    flex-direction: column;
    padding: 5px;
    transition: transform 1s ease;

    &:hover{
        transform: scale(1.1);
        box-shadow: 0 0 5px white;
    }
}

.image-container{
    width: 100%;
    height: 100%;
}

.image-container img{
    width: 100%;
    height: 100%;
}

.desc-container{
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    height: 100%;
    // text-decoration: none;
}

.desc-container h3{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: yellow;
    margin-bottom: 0;
    cursor: pointer;
}

.desc-container p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: white;
}

@media screen and (max-width:786px) {
    .desc-container h3{
        opacity: 1;
        line-height: 10px;
        font-size: 14px;
    }

    .desc-container p{
        line-height: 12px;
        font-size: 10px;
    }
}
