.home-page{
    .text-zone{
        position: absolute;
        left: 15%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
        z-index: 1;
    }

    h1{
        color: white;
        font-size: 53px;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: yellow;
            font-size: 18px;
            position: absolute;
            margin-top: -20px;
            left: -45px;
            opacity: 0.6;
        }

        &::after{
            content: '<h1/>';
            font-family: 'La Belle Aurore';
            color: rgb(255, 255, 0);
            font-size: 18px;
            position: absolute;
            margin-top: 80px;
            left: -45px;
            opacity: 0.6;
            animation: fadeIn 1s 3.5s backwards;
        }

        img{
            width: 32px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
            margin-bottom: -5px;
        }
    }

    h2{
        color: #fe6a00;
        font-size: 14px;
        font-weight: 400;
        margin-top: 60px;
        font-family: 'sans-serif';
        letter-spacing: 3px;
        animation: fadeIn 1s 3.7s backwards;
    }

    .flat-button{
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 4s backwards;
        white-space: nowrap;
        width: 145px;
        text-align: center;

        &:hover{
            background-color: #ffd700;
            color: #333;
        }
    }

    .logo-container{
        z-index: 0;
        width: 400px;
        height: 600px;
        position: absolute;
        top: 0;
        right: 15%;
        bottom: 0;
        left: auto;
        margin: auto;
    
        h3{
            position: absolute;
            right: 83px;
            bottom: 113px;
            color: yellow;
            font-size: 28px;
            font-family: 'La Belle Aurore';
        }
    
        svg{
            position: absolute;
            width: 80%;
            height: auto;
            top: 15%;
            right: 0;
            animation: fill 0.5s linear forwards 3.8s;
            // animation: popIn 2s ease-in-out;
        }
        #img path{
            stroke-dasharray: 2491px; /*N*/
            stroke-dashoffset: 2491px;
            animation: draw 8s ease-in-out forwards;
        }

        @keyframes draw {
            to{
                stroke-dashoffset: 0;
            }    
        }
        
        @keyframes fill {
            to{
                fill: yellow
            }        
        }
    
        // @keyframes popIn {
        //     0%{
        //         transform: scale(0);
        //     }
        //     100%{
        //         transform: scale(1);
        //     }
        // }
    
        .text-animate-hover{
            &:hover{
                color: white;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    h2{
        letter-spacing: 1px !important;
    }
    .logo-container{
        h3{
            display: none;
        }
    
        svg{
            width: 50% !important;
            height: 400px !important;
            top: 0 !important;
            opacity: 0.3;
        }
    }
    .flat-button{
        color: white !important;
        letter-spacing: 0 !important;
        width: 98px !important;
    }
}

@media screen and (max-width: 481px) {
    .home-page h1::after{
        top: 28%;
        font-size: 12px;
        left: 0 !important;
        opacity: 1;
    }
    
    .logo-container{
        svg{
            width: 70% !important;
        }
    }
    h2{
        margin-top: 25px !important;
        font-size: 12px!important;
    }
    h3{
        display: flex !important;
        bottom: 38% !important;
        right: 16.5% !important;
        opacity: 0.4;
    }
}