$primary-color: #ffd700;

@import 'animate.css';
@import '~loaders.css/src/animations/line-spin-fade-loader';
@import '~loaders.css/src/animations/ball-zig-zag-deflect.scss';
@import '~loaders.css/src/animations/ball-clip-rotate-multiple';
@import '~loaders.css/src/animations/line-scale-pulse-out.scss';
@import '~loaders.css/src/animations/ball-rotate';

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}
@font-face {
    font-family: 'La Belle Aurore';
    src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}
@font-face {
    font-family: 'Coolvetica';
    src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}
input,
textarea{
    font-family: 'Helvetica Neue';
}

.loader-active{
    display: block;
    position: absolute;
    left: 50%;right: 50%;
    margin: auto;
    top: 0;bottom: 0;
    width: 50px;
    height: 50px;
    animation: fadeOut 0.5s 0.8s;
    animation-fill-mode: forwards;
}