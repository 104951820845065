.project-container{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
    height: auto;
    top: 10px;
    padding-top: 1%;
    margin-left: 0;
    position: absolute;
    right: 30px;
    animation: fadeInUp 2s;
    animation-fill-mode: forwards;
    clear: both;
    overflow: visible;

    a{
        text-decoration: none;
    }

    .more-button {
        flex: 1;
        position: absolute;
        margin-top: 20px;
        right: 45%;
        border: 1px solid yellow;
        height: 40px;
        width: 100px;
        background: transparent;
        color: yellow;
        text-align: center;
        cursor: pointer;
        animation: popIn 2s ease-in-out;
    }
}



@keyframes popIn {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}

@media screen and (max-width: 768px) {
    .project-container{
        padding: 20px;
        height: 200px;
        position: relative;
        left: 10%;
        width: 70%;
        overflow: scroll;
        background: #444444;


        .more-button{
            right: 34%;
        }
    }
}